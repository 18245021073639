import React from 'react'

import BucketGrid from 'components/StreamingTile/BucketGrid'
import { useLatestValue } from 'hooks/useLatestValue'
import { MarketDataSnapshot } from 'types/api/market-data-snapshot'
import { CurrencyPair } from 'types/currency-pair'
import { MarketDataBucket } from 'types/market-data-bucket'

import { useZippedBidsAndOffers } from './useZippedBidsAndOffers'

export interface VolumeLadderProps {
  'data-test': string
  currencyPair: CurrencyPair
  marketDataSnapshot: MarketDataSnapshot
  sizeBuckets: string[]
  setSizeBuckets: (sb: string[]) => void
}

export const VolumeLadder: React.FC<VolumeLadderProps> = ({
  'data-test': dataTest = 'volume-ladder',
  marketDataSnapshot,
  sizeBuckets,
  setSizeBuckets,
}) => {
  const zippedBidsAndOffers: MarketDataBucket[] = useZippedBidsAndOffers(
    marketDataSnapshot,
    sizeBuckets,
  )

  const latestZippedBidsAndOffers = useLatestValue(zippedBidsAndOffers)

  return (
    <BucketGrid
      data-test={`${dataTest}.bucket-grid`}
      buckets={latestZippedBidsAndOffers.current}
      setSizeBuckets={setSizeBuckets}
    />
  )
}
