import { keyBy } from 'lodash'
import { useMemo } from 'react'

import { calculateSpread } from 'lib/app-util'
import { maybeNumber } from 'lib/decimal-util'
import { MarketDataSnapshot } from 'types/api/market-data-snapshot'
import { MarketDataBucket } from 'types/market-data-bucket'

export const useZippedBidsAndOffers = (
  marketDataSnapshot: MarketDataSnapshot,
  sizeBuckets: string[],
): MarketDataBucket[] =>
  useMemo(() => {
    if (!marketDataSnapshot) return null

    const result = []

    const { Bids, Offers } = marketDataSnapshot
    const bidsBySize = keyBy(Bids, (bid) => maybeNumber(bid?.Size))
    const offersBySize = keyBy(Offers, (offer) => maybeNumber(offer?.Size))

    for (let i = 0; i < sizeBuckets.length; i++) {
      const size = sizeBuckets[i]
      const sizeNum = maybeNumber(sizeBuckets[i])
      const buyPrice = offersBySize[sizeNum]?.Price ?? null
      const sellPrice = bidsBySize[sizeNum]?.Price ?? null

      const index: MarketDataBucket = {
        quantity: size,
        sellPrice,
        buyPrice,
        spread:
          buyPrice && sellPrice
            ? calculateSpread(maybeNumber(buyPrice), maybeNumber(sellPrice))
            : null,
      }

      result.push(index)
    }

    return result
  }, [marketDataSnapshot, sizeBuckets])
