import styled from 'styled-components'
import tw from 'twin.macro'

export interface SelectButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  size?: 'default' | 'large' | 'small'
  hiddenStyle?: boolean
}

export const SelectButton = styled.button<SelectButtonProps>`
  ${tw`
    min-w-[100px]
    h-[32px]
    bg-[rgba(255, 255, 255, 0.05)]
    border
    border-solid
    border-[rgba(0, 0, 0, 0.3)]
    border-radius[4px]
    flex
    justify-between
    items-center
    p-2
    font-ringside-narrow
    font-weight[300]
    text-[12px]
    leading-[14px]
    text-light-500
  `}

  ${tw`focus:border focus:border-solid focus:border-[rgba(255, 255, 255, 0.4)]`}
  ${tw`hover:bg-[rgba(255, 255, 255, 0.05)] hover:border hover:border-[rgba(255, 255, 255, 0.2)]`}
  ${tw`active:bg-[rgba(0, 0, 0, 0.15)] active:text-white active:border-transparent`}

  ${({ hiddenStyle }) =>
    hiddenStyle
      ? tw`
          bg-transparent
          border-transparent
        `
      : null}

${({ size }) =>
    size === 'small'
      ? tw`
          h-[24px]
          min-w-[60px]
        `
      : null}

${({ size }) =>
    size === 'large'
      ? tw`
          h-[33px]
          text-[14px]
          font-weight[350]
        `
      : null}
`
export const SelectWrapper = tw.div`relative max-w-min`

export const SelectText = tw.span`whitespace-nowrap`

export const SelectTextFilled = tw(SelectText)``
