import {
  FC,
  useState,
  Dispatch,
  SetStateAction,
  useCallback,
  useRef,
  useEffect,
} from 'react'
import tw from 'twin.macro'

import Calendar from 'assets/icons/calendar.svg'
import Close from 'assets/icons/close.svg'
import IconButton from 'components/IconButton'
import DatePicker from 'components/InputRow/DatePicker'
import Popover from 'components/Popover'
import { parseDateFromDateInputRowFormat } from 'lib/utils/date'

import { InputRow } from './InputRow'

const OptionalContainer = tw.div`text-light-500 text-[12px] font-weight[300] font-ringside-narrow`
const Container = tw.div`relative w-full`

const parseStringFromDate = (date?: Date) => {
  if (!date) return ''

  const year = date.getFullYear().toString().substring(2)
  const month = date.getMonth() + 1
  const day = date.getDate()
  const hours = date.getHours()
  const minutes =
    date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
  return `${year}/${month}/${day} - ${hours}:${minutes}`
}

export interface DateInputRowProps {
  'data-test': string
  type: 'start' | 'stop'
  date: Date | null
  setDate: Dispatch<SetStateAction<Date>>
}

export const DateInputRow: FC<DateInputRowProps> = ({
  'data-test': dataTest = 'date-input-row',
  type,
  date,
  setDate,
}) => {
  const [openDatePicker, setOpenDatePicker] = useState<boolean>(false)
  const [dateString, setDateString] = useState<string>()
  const containerRef = useRef(null)
  const placeholder = type === 'start' ? 'Start time' : 'End time'

  useEffect(
    () => setDateString(parseStringFromDate(date)),
    [setDateString, date],
  )

  const handleToggleDatePicker = useCallback(
    () => setOpenDatePicker(!openDatePicker),
    [openDatePicker],
  )

  const handlePopoverDismiss = useCallback(() => {
    handleToggleDatePicker()

    if (dateString.length === 0) return setDate(null)
    const parsedDateString = parseStringFromDate(date)
    if (dateString === parsedDateString) return

    const newDate = parseDateFromDateInputRowFormat(dateString)
    if (newDate) setDate(newDate)
    else setDateString(parsedDateString)
  }, [dateString, setDate, date, handleToggleDatePicker])

  const handleToggleClick = useCallback(() => {
    setOpenDatePicker(!openDatePicker)
  }, [openDatePicker])

  return (
    <Container data-test={`${dataTest}.container`} ref={containerRef}>
      <InputRow
        data-test={`${dataTest}.date`}
        placeholder={placeholder}
        type="text"
        value={dateString}
        onChange={setDateString}
      >
        <OptionalContainer>Optional</OptionalContainer>
        <IconButton
          size="small"
          data-test={`${dataTest}.toggle`}
          onClick={handleToggleClick}
        >
          {!openDatePicker && (
            <Calendar
              data-test={`${dataTest}.calendar`}
              tw="w-[16px] h-[16px]"
            />
          )}
          {openDatePicker && (
            <Close
              data-test={`${dataTest}.close`}
              tw="!fill-white w-[16px] h-[16px]"
            />
          )}
        </IconButton>
      </InputRow>
      {openDatePicker && (
        <Popover
          data-test={`${dataTest}.popover`}
          referenceElement={containerRef.current}
          distance={-4}
          placement="bottom"
          onDismiss={handlePopoverDismiss}
        >
          <DatePicker
            data-test={`${dataTest}.picker`}
            date={date}
            setDate={setDate}
          />
        </Popover>
      )}
    </Container>
  )
}
