import { useCallback } from 'react'
import { useSetRecoilState, useRecoilValue } from 'recoil'

import { getDefaultRFQTilePair } from 'lib/utils/rfqTiles'
import { getDefaultStreamingTileSliver } from 'lib/utils/streamingTiles'
import store from 'store'
import { CurrencyPair } from 'types/currency-pair'
import { StreamingTileLocalSliver } from 'types/streaming-tile-local-sliver'

export const useAddTile = () => {
  const viewState = useRecoilValue(store.view.selectedView)
  const currentGroupId = useRecoilValue(store.tileSlice.selectedGroupId)
  const setCurrentGroup = useSetRecoilState(
    store.tileSlice.groupById(currentGroupId),
  )

  const addTileToCurrentGroup = useCallback(() => {
    if (viewState === 'trading') {
      setCurrentGroup((prev) => {
        const next = Array.from(prev as StreamingTileLocalSliver[])
        next.push(getDefaultStreamingTileSliver())
        return next
      })
    } else if (viewState === 'rfq') {
      setCurrentGroup((prev) => {
        const next = Array.from(prev as CurrencyPair[])
        next.push(getDefaultRFQTilePair())
        return next
      })
    }
  }, [setCurrentGroup, viewState])

  return addTileToCurrentGroup
}
