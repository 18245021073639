import tw from 'twin.macro'

export const Wrapper = tw.div`w-full`

export const Container = tw.div`flex w-full relative`

export const RightContainer = tw.div`
  absolute
  right-2
  top-0
  bottom-0
  flex
  gap-1
  items-center
  px-0.5
  font-ringside-narrow
  font-weight[300]
  text-[12px]
  text-gray-500
`

export const ErrorContainer = tw.div`
  p-1
  text-ui-red
  font-ringside-regular
  font-weight[300]
  text-[10px]
  leading-[12px]
`
