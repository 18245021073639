import { Currency } from 'types/currency'
import { CurrencyPair } from 'types/currency-pair'
import { StreamingTileLocalSliver } from 'types/streaming-tile-local-sliver'

export const replaceBaseCurrency =
  (nextBaseCurrency: Currency) => (prevSliver: StreamingTileLocalSliver) => {
    const makeNextCurrencyPair = (
      currencyPair: CurrencyPair,
      baseCurrency: Currency,
    ): CurrencyPair => [baseCurrency, currencyPair[1]]

    const prevCurrencyPair = prevSliver?.currencyPair
    if (!prevCurrencyPair) return prevSliver

    const nextCurrencyPair = makeNextCurrencyPair(
      prevCurrencyPair,
      nextBaseCurrency,
    )

    return {
      ...prevSliver,
      currencyPair: nextCurrencyPair,
    }
  }

export const replaceQuotedCurrency =
  (nextQuotedCurrency: Currency) => (prevSliver: StreamingTileLocalSliver) => {
    const prevCurrencyPair = prevSliver?.currencyPair
    if (!prevCurrencyPair) return prevSliver

    const makeNextCurrencyPair = (
      currencyPair: CurrencyPair,
      quotedCurrency: Currency,
    ): CurrencyPair => [currencyPair[0], quotedCurrency]

    const nextCurrencyPair = makeNextCurrencyPair(
      prevCurrencyPair,
      nextQuotedCurrency,
    )

    return {
      ...prevSliver,
      currencyPair: nextCurrencyPair,
    }
  }

export const replaceAmount =
  (nextAmount: string) => (prevSliver: StreamingTileLocalSliver) => {
    if (!prevSliver) return prevSliver

    return {
      ...prevSliver,
      amount: nextAmount,
    }
  }

export const replaceNormalSize =
  (nextNormalSize: string) => (prevSliver: StreamingTileLocalSliver) => {
    if (!prevSliver) return prevSliver

    return {
      ...prevSliver,
      normalSize: nextNormalSize,
    }
  }

export const replaceSizeBuckets =
  (nextSizeBuckets: string[]) => (prevSliver: StreamingTileLocalSliver) => {
    if (!prevSliver) return prevSliver

    return {
      ...prevSliver,
      sizeBuckets: nextSizeBuckets,
    }
  }

export const replaceVolumeLadderOpen =
  (nextVolumeLadderOpen: boolean) => (prevSliver: StreamingTileLocalSliver) => {
    if (!prevSliver) return prevSliver

    return {
      ...prevSliver,
      volumeLadderOpen: nextVolumeLadderOpen,
    }
  }
