import React, { useCallback, useEffect, useMemo } from 'react'
import { useDrag } from 'react-dnd'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'

import StreamingTile from 'components/StreamingTile'
import { useRemoveTile } from 'hooks/streamingTiles'
import { useCurrencyPairId } from 'hooks/useCurrencyPairId'
import { DraggableItemTypes } from 'lib/draggable-item-types'
import {
  replaceBaseCurrency,
  replaceQuotedCurrency,
  replaceAmount,
  replaceNormalSize,
  replaceSizeBuckets,
  replaceVolumeLadderOpen,
} from 'lib/reducers/streamingTileLocalSliverReducers'
import store from 'store'
import { Currency } from 'types/currency'
import { GroupPosition } from 'types/group'

import { Container } from './StreamingTileSlotConnector.styles'

export type StreamingTileSlotConnectorProps = GroupPosition

const StreamingTileSlotConnector: React.FC<GroupPosition> = (groupPosition) => {
  const setTileBeingDragged = useSetRecoilState(
    store.tileSlice.tileBeingDragged,
  )
  const removeTile = useRemoveTile()
  const [{ isDragging }, drag] = useDrag(() => ({
    type: DraggableItemTypes.TILE,
    item: { position: groupPosition.position },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult<{ position: number }>()
      if (item && dropResult) {
        removeTile(item.position)
      }
      setTileBeingDragged(-1)
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }))
  const removeSelf = useCallback(
    () => removeTile(groupPosition.position), // why doesn't this take `groupPosition`?
    [removeTile, groupPosition],
  )

  const [streamingTileLocalSliver, setStreamingTileLocalSliver] =
    useRecoilState(store.streamingTiles.sliverByPosition(groupPosition))
  const subscriptionParams = useRecoilValue(
    store.streamingTiles.monitorParamsByPosition(groupPosition),
  )

  const topMdsSelectedRung = useRecoilValue(
    store.streamingTiles.topMdsSelectedRung({
      topMonId: subscriptionParams?.top.monId,
      volMonId: subscriptionParams?.vol.monId,
      groupPosition,
    }),
  )
  const volMarketDataSnapshot = useRecoilValue(
    store.marketDataSnapshots(subscriptionParams?.vol.monId),
  )

  const [
    setStreamingTileBaseCurrency,
    setStreamingTileQuotedCurrency,
    setAmount,
    setNormalSize,
    setSizeBuckets,
    setVolumeLadderOpen,
  ] = useMemo(
    () => [
      (baseCurrency: Currency) => {
        setStreamingTileLocalSliver(replaceBaseCurrency(baseCurrency))
      },
      (quotedCurrency: Currency) => {
        setStreamingTileLocalSliver(replaceQuotedCurrency(quotedCurrency))
      },
      (amount: string) => {
        setStreamingTileLocalSliver(replaceAmount(amount))
      },
      (normalSize: string) => {
        setStreamingTileLocalSliver(replaceNormalSize(normalSize))
      },
      (sizeBuckets: string[]) => {
        setStreamingTileLocalSliver(replaceSizeBuckets(sizeBuckets))
      },
      (volumeLadderOpen: boolean) => {
        setStreamingTileLocalSliver(replaceVolumeLadderOpen(volumeLadderOpen))
      },
    ],
    [setStreamingTileLocalSliver],
  )

  const { currencyPair, amount, sizeBuckets, volumeLadderOpen } =
    streamingTileLocalSliver ?? {}

  const currencyPairId = useCurrencyPairId(currencyPair)
  const normalSize = useRecoilValue(
    store.customerSecurities.normalSizeBySymbol(currencyPairId),
  )
  const canonicalSizeBuckets = useRecoilValue(
    store.customerSecurities.canonicalSizeBucketsBySymbol(currencyPairId),
  )

  useEffect(() => {
    if (canonicalSizeBuckets && sizeBuckets?.length === 0) {
      setSizeBuckets(canonicalSizeBuckets)
    }
  }, [canonicalSizeBuckets, sizeBuckets, setSizeBuckets])

  useEffect(() => {
    if (normalSize) {
      setNormalSize(normalSize)
    }
  }, [normalSize, setNormalSize])

  useEffect(() => {
    if (isDragging) setTileBeingDragged(groupPosition.position)
  }, [isDragging, groupPosition, setTileBeingDragged])

  return (
    <Container data-test="connector.container" ref={drag}>
      {streamingTileLocalSliver && (
        <StreamingTile
          data-test="connector.streaming-tile"
          dragged={isDragging}
          currencyPair={currencyPair}
          topMarketDataSnapshot={topMdsSelectedRung}
          volMarketDataSnapshot={volMarketDataSnapshot}
          amount={amount}
          setAmount={setAmount}
          sizeBuckets={sizeBuckets}
          setSizeBuckets={setSizeBuckets}
          volumeLadderOpen={volumeLadderOpen}
          setVolumeLadderOpen={setVolumeLadderOpen}
          onBaseCurrencyChange={setStreamingTileBaseCurrency}
          onQuotedCurrencyChange={setStreamingTileQuotedCurrency}
          removeSelf={removeSelf}
        />
      )}
    </Container>
  )
}

export default StreamingTileSlotConnector
