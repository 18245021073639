import React from 'react'
import tw from 'twin.macro'

import { InputRow } from 'components/InputRow'
import { Currency } from 'types/currency'

const Container = tw.div`flex w-full relative`
const CurrencyContainer = tw.div`font-ringside-narrow font-weight[300] text-[12px] text-white`
const ReqContainer = tw.div`text-light-500 text-[12px] font-ringside-narrow font-weight[300]`

export interface InputPriceProps {
  'data-test': string
  placeholder: string
  currency: Currency
  value: string
  error?: string
  onChange: (nextPrice: string) => void
}

export const InputPrice: React.FC<InputPriceProps> = ({
  'data-test': dataTest = 'input-price',
  placeholder,
  currency,
  value,
  error,
  onChange,
}) => {
  return (
    <Container data-test={`${dataTest}.container`}>
      <InputRow
        data-test={`${dataTest}.input-row`}
        value={value}
        type="number"
        placeholder={placeholder}
        error={error}
        onChange={onChange}
      >
        {value.length === 0 ? (
          <ReqContainer data-test={`${dataTest}.required`}>
            Required
          </ReqContainer>
        ) : null}
        <CurrencyContainer data-test={`${dataTest}.currency`}>
          {currency.Symbol}
        </CurrencyContainer>
      </InputRow>
    </Container>
  )
}
