import tw from 'twin.macro'

export const Container = tw.div`
  font-ringside-narrow
  grid
  grid-cols-4
  text-[14px]
  justify-items-stretch
  h-fc
`

const CellHeader = tw.div`text-light-500 h-[22px] font-medium`
export const QTYCellHeader = tw(CellHeader)`justify-self-start`
export const SellCellHeader = tw(CellHeader)`justify-self-start`
export const SpreadCellHeader = tw(CellHeader)`justify-self-center`
export const BuyCellHeader = tw(CellHeader)`justify-self-end`

const Cell = tw.div`flex items-center h-[30px] text-light-200`
export const QTYCell = tw(
  Cell,
)`font-monument-grotesk justify-center items-center px-[3.4px]`
export const BuyCell = tw(Cell)`justify-end pr-1`
export const SellCell = tw(Cell)`pl-1`
export const SpreadCell = tw(Cell)`justify-center`
