import { FC, ChangeEvent, useState, useEffect, useMemo } from 'react'
import tw from 'twin.macro'

import Input from 'components/Input'
import { getLocalTimeZone } from 'lib/utils/date'

const Container = tw.div``
const InputContainer = tw.div`
  flex
  items-center
  justify-center
  gap-x-0.5
  text-light-500
  px-2
`

const QuickTimeContainer = tw.div`
  h-[32px]
  flex
  gap-[13px]
  justify-evenly
  items-center
  text-[12px]
  border-b-[1px]
  border-[rgba(160, 160, 160, 0.5)]
  text-light-300
  font-ringside-narrow
  px-2
  mb-2
`
const QuickTimeItem = tw.button``

const TimeZone = tw.div`
  font-ringside-narrow
  font-normal
  text-[12px]
  text-light-300
  px-2
`

export interface TimePickerProps {
  'data-test': string
  date: Date
  setHours(hours: number): void
  setMinutes(minutes: number): void
}

export const TimePicker: FC<TimePickerProps> = ({
  'data-test': dataTest = 'time-picker',
  date,
  setHours,
  setMinutes,
}) => {
  const [localMinutes, setLocalMinutes] = useState<string>(
    date.getMinutes().toString(),
  )
  const [localHours, setLocalHours] = useState<string>(
    date.getHours().toString(),
  )

  useEffect(() => {
    setLocalHours(date.getHours().toString())
    setLocalMinutes(date.getMinutes().toString())
  }, [date])

  const [
    setEOD,
    addMinutes,
    addHours,
    handleHourChange,
    handleMinuteChange,
    tz,
  ] = useMemo(() => {
    const setEOD = () => {
      setHours(23)
      setMinutes(59)
    }

    const addMinutes = (n: number) => {
      setMinutes(date.getMinutes() + n)
    }

    const addHours = (n: number) => {
      setHours(date.getHours() + n)
    }

    const handleHourChange = (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target
      setLocalHours(value)

      if (value.length > 0) {
        const parsed = parseInt(value)
        if (parsed <= 23) {
          setHours(parsed)
        }
      }
    }

    const handleMinuteChange = (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target
      setLocalMinutes(value)

      if (value.length > 0) {
        const parsed = parseInt(value)
        if (parsed <= 59) {
          setMinutes(parsed)
        }
      }
    }

    const tz = getLocalTimeZone()
    return [
      setEOD,
      addMinutes,
      addHours,
      handleHourChange,
      handleMinuteChange,
      tz,
    ]
  }, [setHours, setMinutes, date])

  return (
    <Container data-test={`${dataTest}.container`}>
      <QuickTimeContainer>
        {[5, 10, 30, 60].map((n) => (
          <QuickTimeItem
            data-test={`${dataTest}.quick-minute`}
            data-test-arg={n}
            key={n}
            onClick={() => addMinutes(n)}
          >
            {n}m
          </QuickTimeItem>
        ))}
        {[2, 4].map((n) => (
          <QuickTimeItem
            data-test={`${dataTest}.quick-hour`}
            data-test-arg={n}
            key={n}
            onClick={() => addHours(n)}
          >
            {n}h
          </QuickTimeItem>
        ))}
        <QuickTimeItem data-test={`${dataTest}.quick-eod`} onClick={setEOD}>
          EOD
        </QuickTimeItem>
      </QuickTimeContainer>
      <InputContainer>
        <Input
          data-test={`${dataTest}.input-hour`}
          type="number"
          min={0}
          max={24}
          value={localHours}
          onChange={handleHourChange}
          tw="w-[90px]"
        />
        <div>:</div>
        <Input
          data-test={`${dataTest}.input-minute`}
          type="number"
          min={0}
          max={60}
          value={localMinutes}
          onChange={handleMinuteChange}
          tw="w-[90px]"
        />
        <TimeZone data-test={`${dataTest}.timezone`}>
          GMT {tz.sign} {tz.offset}
        </TimeZone>
      </InputContainer>
    </Container>
  )
}
