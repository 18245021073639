import styled from 'styled-components'
import tw from 'twin.macro'

import DeletableContainer from 'components/DeletableContainer'

interface WrapperProps {
  dragged?: boolean
}

export const Wrapper = styled(DeletableContainer)<WrapperProps>`
  ${tw`
    min-w-[308px]
    max-w-min
    bg-light-800
    border
    border-solid
    border-light-700
    border-radius[4px]
    p-2
    flex
    flex-col
    gap-2
    `}

  ${({ dragged }) =>
    !dragged && tw`hover:border-light-500 focus:border-light-500`}
`

export const NotificationsAnchor = tw.div``
