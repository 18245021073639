import { map, range } from 'lodash'
import React, { useMemo, useRef } from 'react'
import { useLatest } from 'react-use'
import 'twin.macro'

import { MarketDataBucket } from 'types/market-data-bucket'

import {
  Container,
  QTYCellHeader,
  SpreadCellHeader,
  SellCellHeader,
  BuyCellHeader,
} from './BucketGrid.styles'
import BucketRow from './BucketRow'

const OPACITY_COEFFICIENT = 0.04

export interface BucketGridProps {
  'data-test': string
  buckets: MarketDataBucket[]
  setSizeBuckets?: (sizeBuckets: string[]) => void
}

const getRowBackground = (index: number): string =>
  `rgba(255, 255, 255, ${
    index * OPACITY_COEFFICIENT <= 0.16 ? index * OPACITY_COEFFICIENT : 0.16
  } )`

const BucketGrid: React.FC<BucketGridProps> = ({
  'data-test': dataTest = 'bucket-grid',
  buckets,
  setSizeBuckets,
}) => {
  const isSubmitting = useRef(false)
  const latestBuckets = useLatest(buckets)

  const getMemoizedRowStyle: (rowOffset: number) => React.CSSProperties =
    useMemo(() => {
      const memo = {}

      return (rowOffset) =>
        memo[rowOffset] ??
        (() => {
          const rowStyle = { background: getRowBackground(rowOffset) }
          memo[rowOffset] = rowStyle
          return rowStyle
        })()
    }, [])

  const getMemoizedSubmit: (rowOffset: number) => (quantity: string) => void =
    useMemo(() => {
      const memo = {}

      return (rowOffset) =>
        memo[rowOffset] ??
        (() => {
          const submit = (quantity: string) => {
            if (isSubmitting.current) {
              return
            }

            isSubmitting.current = true

            const nextBuckets = [
              ...latestBuckets.current.map(({ quantity }) => quantity),
            ]
            nextBuckets[rowOffset] = quantity
            setSizeBuckets(nextBuckets)

            isSubmitting.current = false
          }

          memo[rowOffset] = submit

          return submit
        })()
    }, [isSubmitting, setSizeBuckets, latestBuckets])

  const [styles, submits] = useMemo(() => {
    const len = buckets?.length ?? 0
    const rows = range(len)
    return [rows.map(getMemoizedRowStyle), rows.map(getMemoizedSubmit)]
  }, [buckets?.length, getMemoizedRowStyle, getMemoizedSubmit])

  return (
    <Container data-test={`${dataTest}.container`}>
      <QTYCellHeader>Quantity</QTYCellHeader>
      <SellCellHeader>Sell</SellCellHeader>
      <SpreadCellHeader>Spread</SpreadCellHeader>
      <BuyCellHeader>Buy</BuyCellHeader>
      {map(buckets, (bucket, rowNumber) => (
        <BucketRow
          data-test={`${dataTest}.bucket-row`}
          key={rowNumber}
          style={styles[rowNumber]}
          bucket={bucket}
          submit={submits[rowNumber]}
        />
      ))}
    </Container>
  )
}

export default BucketGrid
