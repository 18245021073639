import { useRef } from 'react'

// like react-use/useLatest except it discards nil values
export const useLatestValue = <T>(value: T): { readonly current: T } => {
  const ref = useRef(value)

  if (!!value) {
    ref.current = value
  }

  return ref
}
