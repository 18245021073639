import styled, { css } from 'styled-components'
import tw from 'twin.macro'

export interface IconButtonProps {
  size?: 'default' | 'small'
}

export const IconButton = styled.button<IconButtonProps>`
  ${tw`
    min-h-[40px]
    p-2.5
    border-radius[4px]
    border
    border-color[transparent]
    font-ringside-narrow
    font-weight[350]
    text-[14px]
    leading-[17px]
    text-gray-200
  `}

  ${tw`focus:border focus:border-solid focus:border-light-700`}
  ${tw`hover:bg-[rgba(255, 255, 255, 0.05)] hover:text-white hover:border hover:border-transparent`}
  ${tw`active:bg-[rgba(0, 0, 0, 0.15)] active:text-white active:border-transparent`}

  & > svg {
    ${tw`w-[22px] fill-[rgba(255,255,255,.32)] `}
  }

  &:focus > svg {
    ${tw`fill-[rgba(255,255,255,.32)]`}
  }

  &:hover > svg {
    ${tw`fill-white `}
  }

  &:active > svg {
    ${tw`fill-white `}
  }

  ${({ size }) =>
    size === 'small' &&
    css`
      ${tw`min-h-[unset] p-1 font-normal text-[12px] leading-[14px]`}

      & > svg {
        width: 15px;
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      ${tw`opacity-30 cursor-default`}
      ${tw`focus:border-transparent`}
      ${tw`hover:bg-transparent hover:border-transparent`}
      ${tw`active:bg-transparent active:border-transparent`}

      &:hover > svg {
        ${tw`fill-light-700 `}
      }
    `}
`
