import { InputRow } from 'components/InputRow'

import { Percentage, Optional } from './InputVariance.styles'

export interface InputVarianceProps {
  'data-test': string
  value: string
  error?: string
  onChange: (value: string) => void
}

export const InputVariance: React.FC<InputVarianceProps> = ({
  'data-test': dataTest = 'input-variance',
  value,
  error,
  onChange,
}) => {
  const isAllowed = (value: string) =>
    Number(value) >= 0 && Number(value) <= 100

  return (
    <InputRow
      data-test={`${dataTest}.input-row`}
      value={value}
      type="number"
      placeholder={'Variance'}
      error={error}
      isAllowed={isAllowed}
      onChange={onChange}
    >
      {value.length === 0 ? (
        <Optional data-test={`${dataTest}.optional`}>Optional</Optional>
      ) : null}
      <Percentage>%</Percentage>
    </InputRow>
  )
}
