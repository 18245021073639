import tw from 'twin.macro'

export const Wrapper = tw.div`
    w-[20px]
    min-h-full
    relative
    flex
    flex-col
    gap[2.5px]
    items-center
    justify-center
`

export const Divider = tw.div`
    w-0
    z-0
    h-full
    absolute
    left-1/2
    top-1/2
    translate-x-[-50%]
    translate-y-[-50%]
    border-r
    border-light-600
    opacity-[0.3]
    border-solid
`

export const SpreadInfo = tw.div`
    z-10
    bg-light-700
    h-[22px]
    min-w-[19px]
    border-radius[4px]
    px-1
    flex
    justify-center
    items-center
    font-ringside-narrow
    font-weight[300]
    text-[12px]
    leading-[14px]
    text-light-500
    cursor-default
`
