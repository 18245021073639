import React, { useCallback, useState } from 'react'
import 'twin.macro'
import NumberFormat, { NumberFormatValues } from 'react-number-format'
import { useLatest } from 'react-use'

import Input from 'components/Input'
import { formatDecimal, toPrecision } from 'lib/decimal-util'
import { MarketDataBucket } from 'types/market-data-bucket'

import { SpreadCell, BuyCell, SellCell, QTYCell } from './BucketGrid.styles'

export interface BucketRowProps {
  'data-test': string
  style: React.CSSProperties
  bucket: MarketDataBucket
  submit: (quantity: string) => void
}

const BucketRow: React.FC<BucketRowProps> = ({
  'data-test': dataTest = 'bucket-row',
  style,
  bucket,
  submit,
}) => {
  const latestBucketQuantity = useLatest(bucket?.quantity)
  const [editedQuantity, setEditedQuantity] = useState<string>()
  const latestEditedQuantity = useLatest(editedQuantity)

  const onQuantityChange = useCallback(
    (numberFormatValues: NumberFormatValues) => {
      const { value } = numberFormatValues

      if (
        (latestEditedQuantity.current &&
          latestEditedQuantity.current !== value) ||
        (!latestEditedQuantity.current &&
          latestBucketQuantity.current !== value)
      ) {
        setEditedQuantity(value)
      }
    },
    [setEditedQuantity, latestBucketQuantity, latestEditedQuantity],
  )

  const maybeSubmit = useCallback(() => {
    const quantity = latestEditedQuantity.current

    if (!!quantity) {
      submit(quantity)
      setEditedQuantity(null)
    }
  }, [submit, latestEditedQuantity, setEditedQuantity])

  const handleOnKeyDown = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        maybeSubmit()
      }
    },
    [maybeSubmit],
  )

  return (
    <>
      <QTYCell data-test={`${dataTest}.quantity`} style={style}>
        <NumberFormat
          thousandSeparator
          customInput={Input}
          hiddenStyle
          value={editedQuantity ?? bucket.quantity}
          onValueChange={onQuantityChange}
          onBlur={maybeSubmit}
          onKeyDown={handleOnKeyDown}
          tw="h-[23px] w-full"
        />
      </QTYCell>
      <SellCell data-test={`${dataTest}.sell`} style={style}>
        {toPrecision(bucket.sellPrice)}
      </SellCell>
      <SpreadCell data-test={`${dataTest}.spread`} style={style}>
        {formatDecimal(bucket.spread?.toFixed(2))}
      </SpreadCell>
      <BuyCell data-test={`${dataTest}.buy`} style={style}>
        {toPrecision(bucket.buyPrice)}
      </BuyCell>
    </>
  )
}

export default BucketRow
