/* eslint-disable import/order */
import { useCallback, useState } from 'react'

import { useSocket } from 'hooks/useSocket'
import {
  createLimitOrderMessage,
  createMarketOrderMessage,
  createSteadyPaceOrderMessage,
  createStopLimitOrderMessage,
} from 'lib/trades'
import { ClipIntervalUnits } from 'types/clip-interval-units'
import { OrderType } from 'types/order-type'

import { OrderParameters } from 'types/api/new-order-single'
import { NewOrderSingleMessage } from 'types/api/new-order-single'
import { Currency } from 'types/currency'
import { CurrencyPair } from 'types/currency-pair'

import { formatClipInterval } from 'lib/app-util'

export const useTradeExecutor = (
  orderType: OrderType,
  pair: CurrencyPair,
  qty: string,
  currency: Currency,
  startTime: Date | null,
  endTime: Date | null,
  price: string,
  stopLimit: string,
  variance: string,
  clipSize: string,
  clipIntervalUnits: ClipIntervalUnits,
  clipIntervalAmount: string,
) => {
  const socket = useSocket()
  const [orderId, setOrderId] = useState<string>('')
  const makeTrade = useCallback(
    (side: 'Buy' | 'Sell') => {
      const makeOrder = (message: NewOrderSingleMessage) => {
        if (socket.readyState === socket.OPEN) {
          socket.send(JSON.stringify(message))
        } else {
          socket.addEventListener('open', () =>
            socket.send(JSON.stringify(message)),
          )
        }
      }
      const params: OrderParameters = {}

      if (startTime) params.StartTime = startTime.toISOString()
      if (endTime) params.EndTime = endTime.toISOString()

      if (orderType === 'market') {
        const message = createMarketOrderMessage(
          pair,
          side,
          qty,
          currency,
          params,
        )
        makeOrder(message)
        setOrderId(message.data[0].ClOrdID)
      } else if (orderType === 'limit') {
        const message = createLimitOrderMessage(
          pair,
          side,
          qty,
          currency,
          price,
          params,
        )
        makeOrder(message)
        setOrderId(message.data[0].ClOrdID)
      } else if (orderType === 'stop-limit') {
        Object.assign(params, {
          TriggerPrice: stopLimit,
        })
        const message = createStopLimitOrderMessage(
          pair,
          side,
          qty,
          currency,
          price,
          params,
        )
        makeOrder(message)
        setOrderId(message.data[0].ClOrdID)
      } else if (orderType === 'steady-pace') {
        Object.assign(params, {
          Variance: variance,
          ClipSize: clipSize,
          ClipInterval: formatClipInterval(
            clipIntervalUnits,
            clipIntervalAmount,
          ),
        })
        const message = createSteadyPaceOrderMessage(
          pair,
          side,
          qty,
          currency,
          params,
        )
        makeOrder(message)
        setOrderId(message.data[0].ClOrdID)
      }
    },
    [
      orderType,
      pair,
      qty,
      startTime,
      endTime,
      socket,
      currency,
      clipIntervalAmount,
      clipIntervalUnits,
      clipSize,
      price,
      stopLimit,
      variance,
    ],
  )

  return { makeTrade, orderId }
}
