import { useCallback } from 'react'
import tw from 'twin.macro'

import { InputRow } from 'components/InputRow'
import Select from 'components/Select'
import { SelectData } from 'components/Select/Select'
import { ClipIntervalUnits } from 'types/clip-interval-units'

const ReqContainer = tw.div`text-light-500 text-[12px] font-ringside-narrow font-weight[300]`

const clipIntervalLabels: Record<ClipIntervalUnits, string> = {
  seconds: 'SEC',
  minutes: 'MIN',
  hours: 'H',
}

const selectData: SelectData[] = Object.entries(clipIntervalLabels).map(
  ([key, value]: [key: ClipIntervalUnits, value: string]) => ({
    label: value,
    value: key,
  }),
)

export interface ClipIntervalSelectProps {
  'data-test': string
  value: string
  clipIntervalUnits: ClipIntervalUnits
  error?: string
  onChange: (input: string, clipIntervalUnits: ClipIntervalUnits) => void
}

export const ClipIntervalSelect: React.FC<ClipIntervalSelectProps> = ({
  'data-test': dataTest = 'clip-internal',
  value,
  clipIntervalUnits = 'seconds',
  error,
  onChange,
}) => {
  const handleSelectOnChange = useCallback(
    (selectValue: string) => {
      const clipIntervalUnits = selectValue as ClipIntervalUnits
      onChange(value, clipIntervalUnits)
    },
    [onChange, value],
  )
  return (
    <InputRow
      data-test={`${dataTest}.input-row`}
      error={error}
      placeholder="Clip Interval"
      type="number"
      value={value}
      onChange={(value: string) => onChange(value, clipIntervalUnits)}
    >
      {value.length === 0 ? (
        <ReqContainer data-test={`${dataTest}.required`}>Required</ReqContainer>
      ) : null}
      <Select
        data-test={`${dataTest}.select`}
        size="small"
        data={selectData}
        value={clipIntervalUnits}
        hiddenStyle
        onChange={(e) => handleSelectOnChange(e.value.value)}
      />
    </InputRow>
  )
}
