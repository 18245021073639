import tw from 'twin.macro'

export const Wrapper = tw.div`
    flex
    justify-between
    items-center
    pl-[9px]
    pr-[3px]
`

export const Toolbar = tw.div`flex gap-2 items-center`
