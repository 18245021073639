import { ReactElement, useCallback, useRef } from 'react'
import NumberFormat, { NumberFormatValues } from 'react-number-format'

import Input from 'components/Input'
import 'twin.macro'

import {
  Container,
  ErrorContainer,
  RightContainer,
  Wrapper,
} from './InputRow.styles'

export interface InputRowProps {
  'data-test': string
  error?: string
  children?: ReactElement | ReactElement[]
  type: 'number' | 'datetime' | 'text'
  placeholder?: string
  value: string
  isAllowed?: (value: string) => boolean // A checker function to check if input value is valid or not. If this function returns false, the onChange method will not get triggered. NOTE: Works only when the type is 'number'
  onChange: (quantity: string) => void
}

export const InputRow: React.FC<InputRowProps> = ({
  'data-test': dataTest = 'input-row',
  error,
  children,
  placeholder,
  type,
  value,
  isAllowed,
  onChange,
}) => {
  const inputRef = useRef<HTMLInputElement>()

  const numberFormatOnChange = useCallback(
    (values: NumberFormatValues) => {
      const { value } = values
      onChange(value)
    },
    [onChange],
  )

  const inputOnChange = useCallback(
    (ev) => {
      onChange(ev.target.value)
    },
    [onChange],
  )

  const handleOnMouseDownRightContainer: React.MouseEventHandler<
    HTMLDivElement
  > = (e) => {
    e.stopPropagation()
    e.preventDefault()
    inputRef.current?.focus()
  }

  return (
    <Wrapper data-test={`${dataTest}.container`}>
      <Container>
        {type === 'number' ? (
          <NumberFormat
            value={value}
            thousandSeparator
            customInput={Input}
            placeholder={placeholder}
            error={error?.trim().length > 0}
            tw="pr-[55%] w-full"
            getInputRef={inputRef}
            isAllowed={(values) => (isAllowed ? isAllowed(values.value) : true)}
            onValueChange={numberFormatOnChange}
          />
        ) : (
          <Input
            data-test={`${dataTest}.text-input`}
            ref={inputRef}
            value={value}
            error={error?.trim().length > 0}
            type={type}
            placeholder={placeholder}
            tw="pr-[55%] w-full"
            onChange={inputOnChange}
          />
        )}
        <RightContainer
          data-test={`${dataTest}.children`}
          onMouseDown={handleOnMouseDownRightContainer}
        >
          {children}
        </RightContainer>
      </Container>
      {error ? (
        <ErrorContainer data-test={`${dataTest}.error`}>{error}</ErrorContainer>
      ) : null}
    </Wrapper>
  )
}
