/**
 * dateComparator - Return 1 of valueA is greater than valueB and 0 if their are equal
 */
export const dateComparator = (valueA, valueB) => {
  if (!valueA) return -1
  if (!valueB) return 1
  const dateA = new Date(valueA).valueOf()
  const dateB = new Date(valueB).valueOf()

  return Number(dateA > dateB) - Number(dateA < dateB)
}

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]
