/* eslint-disable @typescript-eslint/no-unused-vars */
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { useSetRecoilState } from 'recoil'
import 'twin.macro'

import Clock from 'assets/icons/clock.svg'
import Hamburger from 'assets/icons/hamburger.svg'
import Button from 'components/Button'
import IconButton from 'components/IconButton'
import { DateInputRow } from 'components/InputRow'
import NotificationList from 'components/NotificationList'
import { useAvailableBalance } from 'hooks/balance'
import { useHandleTradeAmount } from 'hooks/streamingTiles/useHandleTradeAmount'
import { useValidateTrade } from 'hooks/streamingTiles/useValidateTrade'
import { useOrderStatus } from 'hooks/trade/useOrderStatus'
import { useTradeExecutor } from 'hooks/trade/useTradeExecutor'
import { useNotifications } from 'hooks/useNotifications'
import { useTopRungPriceAndSpread } from 'hooks/useTopRungPriceAndSpread'
import store from 'store'
import { MarketDataSnapshot } from 'types/api/market-data-snapshot'
import { ClipIntervalUnits } from 'types/clip-interval-units'
import { Currency } from 'types/currency'
import { CurrencyPair } from 'types/currency-pair'
import { OrderType } from 'types/order-type'
import { Side } from 'types/side'

import ClipIntervalSelect from './ClipIntervalSelect'
import Header from './Header'
import InputPrice from './InputPrice'
import InputVariance from './InputVariance'
import Prices from './Prices'
import { NotificationsAnchor, Wrapper } from './StreamingTile.styles'
import TradeAmount from './TradeAmount'
import VolumeLadder from './VolumeLadder'

export interface StreamingTileProps {
  'data-test': string
  currencyPair: CurrencyPair
  topMarketDataSnapshot: MarketDataSnapshot
  volMarketDataSnapshot: MarketDataSnapshot
  sizeBuckets: string[]
  setSizeBuckets: (sb: string[]) => void
  volumeLadderOpen: boolean
  setVolumeLadderOpen: (showLadder: boolean) => void
  amount: string
  setAmount: (amt: string) => void
  dragged?: boolean
  onBaseCurrencyChange?: (value: Currency) => void
  onQuotedCurrencyChange?: (value: Currency) => void
  removeSelf?: () => void
}

export const StreamingTile: React.FC<StreamingTileProps> = ({
  'data-test': dataTest = 'streaming-tile',
  currencyPair,
  topMarketDataSnapshot,
  volMarketDataSnapshot,
  sizeBuckets,
  setSizeBuckets,
  volumeLadderOpen,
  setVolumeLadderOpen,
  amount,
  dragged,
  setAmount,
  onBaseCurrencyChange,
  onQuotedCurrencyChange,
  removeSelf,
}) => {
  const [baseCurrency, quotedCurrency] = currencyPair
  const setQuickRFQCurrencyPair = useSetRecoilState(store.quickRFQ.currencyPair)
  const [orderType, setOrderType] = useState<OrderType>('market')

  const [showStartTime, setShowStartTime] = useState<boolean>(false)

  const [amountError, setAmountError] = useState<string | null>()
  const { buyPrice, sellPrice, spread } = useTopRungPriceAndSpread(
    topMarketDataSnapshot,
  )

  const [baseCurrencyAvailable, quotedCurrencyAvailable] =
    useAvailableBalance(currencyPair)

  useEffect(() => {
    setAmount('')
    setAmountError('')
  }, [currencyPair, setAmount, setAmountError])

  const [limitPrice, setLimitPrice] = useState<string>('')
  const [stopPrice, setStopPrice] = useState<string>('')

  const [clipSize, setClipSize] = useState<string>('')
  const [clipSizeError, setClipSizeError] = useState<string | null>(null)

  const [clipIntervalUnits, setClipIntervalUnits] =
    useState<ClipIntervalUnits>('seconds')
  const [clipIntervalAmount, setClipIntervalAmount] = useState<string>('')

  const [variance, setVariance] = useState<string>('')

  const [startTime, setStartTime] = useState<Date | null>(null)
  const [endTime, setEndTime] = useState<Date | null>(null)
  const [tradeCurrency, setTradeCurrency] = useState<Currency>(baseCurrency)

  const { makeTrade, orderId } = useTradeExecutor(
    orderType,
    currencyPair,
    amount,
    tradeCurrency,
    startTime,
    endTime,
    limitPrice.replaceAll(',', ''),
    stopPrice.replaceAll(',', ''),
    variance,
    clipSize,
    clipIntervalUnits,
    clipIntervalAmount,
  )

  const { validity, trigger, reset } = useValidateTrade(
    orderType,
    amount,
    limitPrice,
    stopPrice,
    clipSize,
    clipIntervalAmount,
  )
  const [anchorReferenceElement, setAnchorReferenceElement] =
    useState<HTMLDivElement | null>(null)

  const {
    add: addNotification,
    remove: removeNotification,
    notifications,
  } = useNotifications()

  useOrderStatus(orderId, addNotification)

  const handleTradeAmountChange = useHandleTradeAmount(
    currencyPair,
    tradeCurrency,
    baseCurrencyAvailable,
    quotedCurrencyAvailable,
    setAmount,
    setAmountError,
  )

  const sell = useCallback(() => {
    const isDirty = trigger()
    if (!isDirty) {
      makeTrade('Sell')
      reset()
    }
  }, [makeTrade, reset, trigger])

  const buy = useCallback(() => {
    const isDirty = trigger()
    if (!isDirty) {
      makeTrade('Buy')
      reset()
    }
  }, [makeTrade, reset, trigger])

  const handleShowDatePicker = useCallback(
    () => setShowStartTime((prev) => !prev),
    [setShowStartTime],
  )

  const [setBaseCurrency, setQuotedCurrency] = useMemo(
    () => [
      (value: Currency) => {
        const symbol = value?.Symbol
        const baseCurrencySymbol = currencyPair?.[0]?.Symbol

        if (baseCurrencySymbol !== symbol) {
          onBaseCurrencyChange(value)
        }
      },
      (value: Currency) => {
        const symbol = value?.Symbol
        const quotedCurrencySymbol = currencyPair?.[1]?.Symbol

        if (quotedCurrencySymbol !== symbol) {
          onQuotedCurrencyChange(value)
        }
      },
    ],
    [currencyPair, onBaseCurrencyChange, onQuotedCurrencyChange],
  )

  const handleClipIntervalChange = useCallback(
    (input: string, clipIntervalUnits: ClipIntervalUnits) => {
      setClipIntervalAmount(input)
      setClipIntervalUnits(clipIntervalUnits)
    },
    [setClipIntervalAmount, setClipIntervalUnits],
  )

  const [sideSelected, setSideSelected] = useState<Side>(null)

  const handleOnBuyPriceSelect = useCallback(() => {
    setSideSelected('buy')
  }, [])

  const handleOnSellPriceSelect = useCallback(() => {
    setSideSelected('sell')
  }, [])

  const handleOnPricesBlur = useCallback(() => {
    setSideSelected(null)
  }, [])

  const handleOnMouseDownPlaceOrderClick: React.MouseEventHandler<HTMLButtonElement> =
    useCallback((e) => {
      e.preventDefault()
      e.stopPropagation()
    }, [])

  const handleOnPlaceOrderClick = useCallback(() => {
    if (!sideSelected) {
      addNotification({
        type: 'error',
        title: 'Place order error',
        message: 'You have to select a side first',
      })
    }
    if (sideSelected === 'buy') {
      buy()
    }
    if (sideSelected === 'sell') {
      sell()
    }
  }, [addNotification, buy, sell, sideSelected])

  /** Checking if the clip size is less or equal to the trade amount */
  const checkClipSizeError = useCallback(
    (clipSize: string, tradeAmount: string) => {
      const parsedClipSize = Number(clipSize)
      const parsedTradeAmount = Number(tradeAmount)

      if (isNaN(parsedClipSize) || isNaN(parsedTradeAmount)) return

      if (parsedClipSize <= parsedTradeAmount) {
        setClipSizeError(null)
      } else {
        setClipSizeError('Clip size must be less than the trade amount value')
      }
    },
    [],
  )

  const handleClipSizeChange = useCallback(
    (value: string) => {
      setClipSize(value)
      checkClipSizeError(value, amount)
    },
    [amount, checkClipSizeError],
  )

  const handleQuickRFQ = useCallback(
    () => setQuickRFQCurrencyPair(currencyPair),
    [currencyPair, setQuickRFQCurrencyPair],
  )

  const toggleVolumeLadderOpen = () => setVolumeLadderOpen(!volumeLadderOpen)

  return (
    <Wrapper
      data-test={`${dataTest}.container`}
      dragged={dragged}
      onDeleteClick={removeSelf}
    >
      <NotificationsAnchor ref={setAnchorReferenceElement} />
      <Header
        data-test={`${dataTest}.header`}
        baseCurrency={baseCurrency}
        quotedCurrency={quotedCurrency}
        orderStatus={orderType}
        onOrderChange={setOrderType}
        onRFQClick={handleQuickRFQ}
        setBaseCurrency={setBaseCurrency}
        setQuotedCurrency={setQuotedCurrency}
      />
      <Prices
        data-test={`${dataTest}.prices`}
        buyPrice={buyPrice}
        sellPrice={sellPrice}
        spread={spread}
        orderType={orderType}
        onBuyConfirm={buy}
        onSellConfirm={sell}
        onBuySelect={handleOnBuyPriceSelect}
        onSellSelect={handleOnSellPriceSelect}
        onBuyBlur={handleOnPricesBlur}
        onSellBlur={handleOnPricesBlur}
      />

      <TradeAmount
        data-test={`${dataTest}.trade-amount`}
        baseCurrency={baseCurrency}
        quotedCurrency={quotedCurrency}
        maxAvailableBase={baseCurrencyAvailable}
        maxAvailableQuoted={quotedCurrencyAvailable}
        amount={amount}
        onAmountChange={handleTradeAmountChange}
        onCurrencyChange={setTradeCurrency}
        error={amountError || validity.amount}
      />
      {orderType === 'limit' ||
      orderType === 'stop-limit' ||
      orderType === 'steady-pace' ? (
        <InputPrice
          data-test={`${dataTest}.price-limit`}
          error={validity.limitPrice}
          placeholder="Limit Price"
          value={limitPrice}
          onChange={setLimitPrice}
          currency={quotedCurrency}
        />
      ) : null}

      {orderType === 'stop-limit' ? (
        <InputPrice
          data-test={`${dataTest}.price-stop`}
          error={validity.stopPrice}
          placeholder="Stop Price"
          value={stopPrice}
          onChange={setStopPrice}
          currency={quotedCurrency}
        />
      ) : null}

      {orderType === 'steady-pace' ? (
        <InputPrice
          data-test={`${dataTest}.clip-size`}
          placeholder="Clip Size"
          value={clipSize}
          onChange={handleClipSizeChange}
          currency={tradeCurrency}
          error={clipSizeError || validity.clipSize}
        />
      ) : null}

      {orderType === 'steady-pace' ? (
        <ClipIntervalSelect
          data-test={`${dataTest}.clip-interval`}
          value={clipIntervalAmount}
          clipIntervalUnits={clipIntervalUnits}
          onChange={handleClipIntervalChange}
          error={validity.clipIntervalAmount}
        />
      ) : null}

      {orderType === 'steady-pace' ? (
        <InputVariance
          data-test={`${dataTest}.variance`}
          value={variance}
          onChange={setVariance}
          error={validity.variance}
        />
      ) : null}

      {showStartTime && (
        <Fragment>
          <DateInputRow
            data-test={`${dataTest}.start`}
            date={startTime}
            setDate={setStartTime}
            type="start"
          />
          <DateInputRow
            data-test={`${dataTest}.end`}
            date={endTime}
            setDate={setEndTime}
            type="stop"
          />
        </Fragment>
      )}

      {orderType !== 'market' ? (
        <Button
          data-test={`${dataTest}.submit`}
          tw="flex justify-center"
          disabled={!sideSelected}
          onMouseDown={handleOnMouseDownPlaceOrderClick}
          onClick={handleOnPlaceOrderClick}
        >
          <span>Place Order</span>
        </Button>
      ) : null}

      <div tw="flex justify-between">
        <IconButton
          data-test={`${dataTest}.toggle-ladder`}
          size="small"
          onClick={toggleVolumeLadderOpen}
        >
          <Hamburger style={volumeLadderOpen ? { fill: 'white' } : {}} />
        </IconButton>
        <IconButton
          data-test={`${dataTest}.toggle-clock`}
          size="small"
          onClick={handleShowDatePicker}
        >
          <Clock style={showStartTime ? { fill: 'white' } : {}} />
        </IconButton>
      </div>

      {volumeLadderOpen && (
        <VolumeLadder
          data-test={`${dataTest}.volume-ladder`}
          currencyPair={currencyPair}
          marketDataSnapshot={volMarketDataSnapshot}
          sizeBuckets={sizeBuckets}
          setSizeBuckets={setSizeBuckets}
        />
      )}

      <NotificationList
        data-test={`${dataTest}.notification-list`}
        notifications={notifications}
        onCloseNotification={removeNotification}
        referenceElement={anchorReferenceElement}
      />
    </Wrapper>
  )
}
