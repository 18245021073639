import 'twin.macro'

import PriceButton from 'components/StreamingTile/PriceButton'
import SpreadIndicator from 'components/StreamingTile/SpreadIndicator'
import { OrderType } from 'types/order-type'

import { Wrapper } from './Prices.styles'

export interface PricesProps {
  'data-test': string
  sellPrice: string
  buyPrice: string
  spread: string
  orderType: OrderType
  onBuyConfirm?: () => void
  onSellConfirm?: () => void
  onBuySelect?: () => void
  onSellSelect?: () => void
  onBuyBlur?: () => void
  onSellBlur?: () => void
}

export const Prices: React.FC<PricesProps> = ({
  'data-test': dataTest = 'prices',
  sellPrice = '0.00',
  buyPrice = '0.00',
  spread = '0',
  orderType,
  onBuyConfirm,
  onSellConfirm,
  onBuySelect,
  onSellSelect,
  onBuyBlur,
  onSellBlur,
}) => {
  return (
    <Wrapper data-test={`${dataTest}.container`}>
      <PriceButton
        data-test={`${dataTest}.sell`}
        side="sell"
        orderType={orderType}
        price={sellPrice}
        onConfirm={onSellConfirm}
        onSelect={onSellSelect}
        onBlur={onSellBlur}
      />
      <SpreadIndicator data-test={`${dataTest}.spread`} spread={spread} />
      <PriceButton
        data-test={`${dataTest}.buy`}
        side="buy"
        orderType={orderType}
        price={buyPrice}
        onConfirm={onBuyConfirm}
        onSelect={onBuySelect}
        onBlur={onBuyBlur}
      />
    </Wrapper>
  )
}
