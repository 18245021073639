import { add, sub } from 'date-fns'
import {
  Dispatch,
  FC,
  useState,
  SetStateAction,
  KeyboardEvent,
  useMemo,
  useEffect,
} from 'react'
import ReactDatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import { monthNames } from 'lib/date-util'

import {
  DatePickerStyles,
  Container,
  DatePickerContainer,
} from './DatePicker.styles'
import { DatePickerHeader } from './DatePickerHeader'
import { GridPopOver } from './GridPopOver'
import { TimePicker } from './TimePicker'

export interface DatePickerProps {
  'data-test': string
  date?: Date
  setDate: Dispatch<SetStateAction<Date>>
}

export const DatePicker: FC<DatePickerProps> = ({
  'data-test': dataTest = 'date-picker',
  date,
  setDate,
}) => {
  const [localDate, setLocalDate] = useState<Date>(date || new Date())
  const [showMonthPicker, setShowMonthPicker] = useState<boolean>(false)

  useEffect(() => setDate(localDate), [localDate, setDate])

  const [increaseMonth, decreaseMonth, setMonth, setHours, setMinutes] =
    useMemo(() => {
      const increaseMonth = () => {
        setLocalDate((prev) => {
          const nextDate = add(prev, { months: 1 })
          return nextDate
        })
      }

      const decreaseMonth = () => {
        setLocalDate((prev) => {
          const nextDate = sub(prev, { months: 1 })
          return nextDate
        })
      }

      const setMonth = (month: number) => {
        setLocalDate((prev) => {
          const nextDate = new Date(prev)
          nextDate.setMonth(month)
          return nextDate
        })
      }

      const setHours = (hours: number) => {
        setLocalDate((prev) => {
          const nextDate = new Date(prev)
          nextDate.setHours(hours)
          return nextDate
        })
      }

      const setMinutes = (minutes: number) => {
        setLocalDate((prev) => {
          const nextDate = new Date(prev)
          nextDate.setMinutes(minutes)
          return nextDate
        })
      }
      return [increaseMonth, decreaseMonth, setMonth, setHours, setMinutes]
    }, [setLocalDate])

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      setDate(localDate)
    }
  }

  return (
    <Container data-test={`${dataTest}.container`} onKeyDown={handleKeyDown}>
      <DatePickerStyles>
        <TimePicker
          data-test={`${dataTest}.time-picker`}
          date={localDate}
          setHours={setHours}
          setMinutes={setMinutes}
        />
        <DatePickerHeader
          data-test={`${dataTest}.date-header`}
          date={localDate}
          decreaseMonth={decreaseMonth}
          increaseMonth={increaseMonth}
          showMonthPicker={showMonthPicker}
          toggleMonthPicker={() => setShowMonthPicker((prev) => !prev)}
        />
        <DatePickerContainer>
          <ReactDatePicker
            disabledKeyboardNavigation
            inline
            renderCustomHeader={() => <></>}
            onChange={(date) => {
              setLocalDate(date)
            }}
            selected={localDate}
          />
        </DatePickerContainer>
        {showMonthPicker && (
          <GridPopOver
            data-test={`${dataTest}.grid-pop-over`}
            items={monthNames.map((m) => m.substring(0, 3))}
            handleClick={(_, index) => {
              setMonth(index)
              setShowMonthPicker(false)
            }}
          />
        )}
      </DatePickerStyles>
    </Container>
  )
}
