import { FC } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'

const Container = tw.div`absolute w-full h-[230px] top-[100px] left-0 bg-light-700 mt-4`
const Grid = tw.div`grid grid-cols-4 gap-y-0  justify-center`
const Cell = styled.button`
  ${tw`hover:bg-white hover:text-gray-900 h-[26px] w-[60px] font-ringside-narrow text-[12px] text-gray-200 rounded-[4px]`}
`

export interface GridPopOverProps {
  'data-test': string
  items: string[]
  handleClick(item: string, index: number): void
}

export const GridPopOver: FC<GridPopOverProps> = ({
  'data-test': dataTest = 'grid-pop-over',
  items,
  handleClick,
}) => {
  return (
    <Container data-test={`${dataTest}.container`}>
      <Grid>
        {items.map((item, index) => (
          <Cell
            data-test={`${dataTest}.item`}
            data-test-arg={item}
            key={item}
            onClick={() => handleClick(item, index)}
          >
            {item}
          </Cell>
        ))}
      </Grid>
    </Container>
  )
}
