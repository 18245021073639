import 'twin.macro'

import { Wrapper, Divider, SpreadInfo } from './SpreadIndicator.styles'

export interface SpreadIndicatorProps {
  'data-test': string
  spread: string
}

export const SpreadIndicator: React.FC<SpreadIndicatorProps> = ({
  'data-test': dataTest = 'spread-indicator',
  spread = '00',
}) => {
  return (
    <Wrapper data-test={`${dataTest}.container`}>
      <Divider />
      <SpreadInfo className="group">
        <span data-test={`${dataTest}.spread`}>{spread}</span>
        <span tw="ml-0.5 hidden group-hover:block">BPS</span>
      </SpreadInfo>
    </Wrapper>
  )
}
