import { useState, useCallback, useEffect } from 'react'

import { OrderType } from 'types/order-type'

type TradeField =
  | 'amount'
  | 'limitPrice'
  | 'stopPrice'
  | 'variance'
  | 'clipSize'
  | 'clipIntervalAmount'

type FieldValidity = Record<TradeField, string>

const defaultValidity = {
  amount: '',
  limitPrice: '',
  stopPrice: '',
  clipSize: '',
  clipIntervalAmount: '',
  variance: '',
}

export const useValidateTrade = (
  orderType: OrderType,
  amount: string,
  limitPrice: string,
  stopPrice: string,
  clipSize: string,
  clipIntervalAmount: string,
) => {
  const [validity, setValidity] = useState<FieldValidity>(defaultValidity)
  const [enableValidation, setEnableValidation] = useState(false)

  const setValidityForField = useCallback(
    (field: TradeField, status: string) =>
      setValidity((prev) => ({
        ...prev,
        [field]: status,
      })),
    [setValidity],
  )

  const validateQuantity = useCallback(
    (value: string, field: TradeField) => {
      if (value.length === 0) {
        setValidityForField(field, 'Field is required')
        return true
      }

      setValidityForField(field, '')
      return false
    },
    [setValidityForField],
  )

  const validate = useCallback(() => {
    if (orderType === 'market') {
      return validateQuantity(amount, 'amount')
    }

    if (orderType === 'limit') {
      return (
        validateQuantity(limitPrice, 'limitPrice') ||
        validateQuantity(amount, 'amount')
      )
    }
    if (orderType === 'steady-pace') {
      return (
        validateQuantity(limitPrice, 'limitPrice') ||
        validateQuantity(clipSize, 'clipSize') ||
        validateQuantity(clipIntervalAmount, 'clipIntervalAmount') ||
        validateQuantity(amount, 'amount')
      )
    }
    if (orderType === 'stop-limit') {
      return (
        validateQuantity(limitPrice, 'limitPrice') ||
        validateQuantity(stopPrice, 'stopPrice') ||
        validateQuantity(amount, 'amount')
      )
    }
  }, [
    amount,
    limitPrice,
    clipSize,
    orderType,
    stopPrice,
    clipIntervalAmount,
    validateQuantity,
  ])

  useEffect(() => {
    if (enableValidation) {
      validate()
    }
  }, [enableValidation, validate])

  const trigger = useCallback(() => {
    setEnableValidation(true)
    return validate()
  }, [setEnableValidation, validate])

  const reset = useCallback(() => {
    setValidity(defaultValidity)
    setEnableValidation(false)
  }, [setValidity, setEnableValidation])

  return { validity, trigger, reset }
}
