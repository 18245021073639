import styled from 'styled-components'
import tw from 'twin.macro'

export const Container = tw.div`bg-light-700 rounded-[4px]`

export const DatePickerStyles = styled.div`
  margin: 0;
  padding: 0;
  .react-datepicker__month-container {
    ${tw`p-0 m-0 border-0`}
  }

  .react-datepicker {
    border: none;
  }

  .react-datepicker__header:not(.react-datepicker__header--has-time-select) {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }

  .react-datepicker__day-names {
    ${tw`bg-light-700 p-0 m-0 border-0`}
  }

  .react-datepicker__day-name {
    ${tw`font-ringside-narrow font-semibold text-[10px] text-gray-200 opacity-50`}
  }

  .react-datepicker__month {
    ${tw`font-monument-grotesk bg-light-700 p-0 m-0 border-0`}
  }

  .react-datepicker__day {
    ${tw`font-ringside-narrow font-weight[300] text-[12px] text-light-400`}
  }

  .react-datepicker__day:hover {
    ${tw`text-gray-900 bg-gray-200 rounded-[34px]`}
  }

  .react-datepicker__day--keyboard-selected {
  }

  .react-datepicker__day--selected {
    ${tw`font-normal bg-white rounded-[34px] text-gray-900`}
  }

  .react-datepicker__day--outside-month {
    ${tw`text-gray-500`}
  }

  .react-datepicker__header {
    ${tw`bg-light-700 p-0 pb-[5px] m-0 border-0`}
  }
`

export const DatePickerContainer = tw.div`flex justify-center`
