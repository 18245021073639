import { FC } from 'react'
import tw from 'twin.macro'

import ArrowDropDown from 'assets/icons/arrow-drop-down.svg'
import ArrowLeft from 'assets/icons/arrow-left.svg'
import ArrowRight from 'assets/icons/arrow-right.svg'
import { monthNames } from 'lib/date-util'

const Container = tw.div`flex justify-between py-2 px-[13px]`
const ArrowButton = tw.button`text-white`
const HeaderText = tw.button`text-light-400 text-[16px] font-weight[350] font-ringside-narrow`
const HeaderContainer = tw.div`flex gap-2 cursor-pointer`
const ArrowDropDownIcon = tw(ArrowDropDown)`w-[24px] fill-gray-500`
const ArrowLeftIcon = tw(ArrowLeft)`h-[16px] w-[16px] fill-white`
const ArrowRightIcon = tw(ArrowRight)`h-[16px] w-[16px] fill-light-400`

const getMonthName = (date: Date) => monthNames[date.getMonth()]

export interface DatePickerHeaderProps {
  'data-test': string
  date: Date
  showMonthPicker: boolean
  decreaseMonth(): void
  increaseMonth(): void
  toggleMonthPicker(): void
}

export const DatePickerHeader: FC<DatePickerHeaderProps> = ({
  'data-test': dataTest = 'date-picker-header',
  date,
  showMonthPicker,
  decreaseMonth,
  increaseMonth,
  toggleMonthPicker,
}) => {
  return (
    <Container data-test={`${dataTest}.container`}>
      <ArrowButton data-test={`${dataTest}.decrement`} onClick={decreaseMonth}>
        <ArrowLeftIcon />
      </ArrowButton>
      <HeaderContainer
        data-test={`${dataTest}.header`}
        onClick={toggleMonthPicker}
      >
        {!showMonthPicker && (
          <HeaderText data-test={`${dataTest}.month`}>
            {getMonthName(date)}
          </HeaderText>
        )}
        <HeaderText data-test={`${dataTest}.year`}>
          {date.getFullYear()}
        </HeaderText>
        {!showMonthPicker && <ArrowDropDownIcon />}
      </HeaderContainer>
      <ArrowButton data-test={`${dataTest}.increment`} onClick={increaseMonth}>
        <ArrowRightIcon />
      </ArrowButton>
    </Container>
  )
}
