import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import 'twin.macro'

import ChevronDown from 'assets/icons/chevron-down.svg'
import List from 'components/List'
import ListItem from 'components/ListItem'
import Popover from 'components/Popover'
import useOutsideClick from 'hooks/useOutsideClick'

import {
  SelectButton,
  SelectText,
  SelectTextFilled,
  SelectWrapper,
} from './Select.styles'

export interface SelectData {
  label: string
  value: string
}
export interface SelectProps {
  'data-test': string
  data: SelectData[]
  value?: SelectData['value']
  onChange: (e: { value: SelectData }) => void
  size?: 'default' | 'large' | 'small'
  hiddenStyle?: boolean
  placeholder?: string
}

export const Select: React.FC<SelectProps> = ({
  'data-test': dataTest = 'select',
  data,
  value,
  onChange,
  hiddenStyle,
  size,
  placeholder,
}) => {
  const [currentValue, setCurrentValue] = useState<SelectData['value'] | null>(
    value ?? null,
  )

  const buttonRef = useRef()

  useEffect(() => {
    if (value) {
      setCurrentValue(value)
    }
  }, [value])

  const [showList, setShowList] = useState<boolean>(false)

  useOutsideClick(buttonRef.current, () => setShowList(false))

  const getLabelFromValue = useCallback(
    (value: SelectData['value']): SelectData['label'] | null => {
      const found = data.find((element) => element.value === value)

      if (found) return found.label

      return null
    },
    [data],
  )

  const currentLabelText = useMemo(
    () => getLabelFromValue(currentValue),
    [currentValue, getLabelFromValue],
  )
  // Event handler for keydowns
  const handleKeyDown = useCallback(
    (value: SelectData['value']) => (e) => {
      switch (e.key) {
        case ' ':
        case 'SpaceBar':
        case 'Enter':
          if (currentValue !== value) {
            e.preventDefault()
            setCurrentValue(value)
            setShowList(false)
            onChange({ value: { label: getLabelFromValue(value), value } })
          }
          break
      }
    },
    [currentValue, getLabelFromValue, onChange],
  )

  const Option = useCallback(
    (value: SelectData['value'], label: SelectData['label']) => (
      <ListItem
        data-test={`${dataTest}.option`}
        data-test-arg={value}
        id={value}
        role="option"
        aria-selected={currentValue === value}
        disabled={currentValue === value}
        tabIndex={0}
        key={value}
        onKeyDown={handleKeyDown(value)}
        onClick={() => {
          if (currentValue !== value) {
            setCurrentValue(value)
            setShowList(false)
            onChange({ value: { label, value } })
          }
        }}
      >
        {label}
      </ListItem>
    ),
    [dataTest, currentValue, handleKeyDown, onChange],
  )

  const Options = useMemo(
    () => data.map((item) => Option(item.value, item.label)),
    [Option, data],
  )

  const handleOnMouseDownList: React.MouseEventHandler<HTMLElement> =
    useCallback((e) => {
      e.preventDefault()
      e.stopPropagation()
    }, [])

  return (
    <SelectWrapper data-test={`${dataTest}.container`}>
      <SelectButton
        data-test={`${dataTest}.toggle`}
        ref={buttonRef}
        aria-haspopup="listbox"
        aria-expanded={showList}
        aria-label={currentLabelText}
        hiddenStyle={hiddenStyle}
        size={size}
        onClick={() => setShowList(!showList)}
      >
        {currentLabelText ? (
          <SelectTextFilled data-test={`${dataTest}.current-label`}>
            {currentLabelText}
          </SelectTextFilled>
        ) : (
          <SelectText data-test={`${dataTest}.placeholder`}>
            {placeholder}
          </SelectText>
        )}
        <ChevronDown tw=" ml-[4px] w-[16px] fill-light-300" />
      </SelectButton>
      {showList ? (
        <Popover
          data-test={`${dataTest}.popover`}
          referenceElement={buttonRef.current}
          placement="bottom-end"
          distance={8}
        >
          <List
            data-test={`${dataTest}.list`}
            role="listbox"
            aria-activedescendant={currentValue}
            tabIndex={-1}
            tw="min-w-[107px]"
            onMouseDown={handleOnMouseDownList}
          >
            {Options}
          </List>
        </Popover>
      ) : null}
    </SelectWrapper>
  )
}
